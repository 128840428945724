import React, { useState } from "react"
import styled from "styled-components"
import LoginLayout from "layouts/LoginLayout"
import { atOutline, chevronBackOutline } from "ionicons/icons"
import Button from "components/Button"
import Logo from "components/Logo"
import { useAuthToken } from "services/authToken"
import { navigate } from "gatsby"
import { useUserQuery } from "services/getUserData"
import Input from "components/Input"
import Link from "components/Link"
import Alert from "components/Alert/Alert"
import Spinner from "components/Spinner"
import { useGuestApolloClient } from "services/client"
import { ApolloProvider, gql, useMutation } from "@apollo/client"
import Heading from "components/Heading"
import Widget, { WidgetSection } from "components/Widget"

const LinkWrapper = styled.div`
  margin-bottom: 18px;
`

const Form = styled.form`
  display: block;
  width: 300px;
  max-width: calc(100vw - 48px);
`

const StyledLogo = styled(Logo)`
  width: 160px;
  margin-bottom: 24px;
`

const LinksWrapper = styled.div`
  margin-top: 32px;
  text-align: center;
`

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`

const ResetContent = () => {
  const [email, setEmail] = useState("")
  const [authToken, removeAuthToken] = useAuthToken()
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const [forgotMutation] = useMutation(FORGOT_PASSWORD_MUTATION)

  const userData = useUserQuery().data

  if (authToken && userData) {
    navigate("/konto")
  } else {
    removeAuthToken()
  }

  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    setErrorMessage("")
    setSuccessMessage("")
    setEmail(e.currentTarget.value)
  }

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    setLoading(true)
    if (!email) {
      setErrorMessage("Podaj adres email")
      setLoading(false)
      return
    }
    forgotMutation({
      variables: {
        email: email,
      },
    })
      .then(res => {
        if (res.data) {
          setSuccessMessage("Email z linkiem został wysłany.")
        }
        setLoading(false)
      })
      .catch(err => {
        if (err.message.startsWith("Bad Request")) {
          setErrorMessage("Użytkownik z podanym e-mailem nie istnieje.")
        } else {
          setErrorMessage("Wystąpił błąd serwera. Proszę spróbować później.")
        }

        setLoading(false)
      })
  }

  return (
    <LoginLayout>
      <StyledLogo />
      <Widget white>
        <WidgetSection>
          <Heading center>Zresetuj hasło</Heading>
          <p style={{ textAlign: "center" }}>
            Podaj adres e-mail, na który
            <br />
            wyślemy link do resetu hasła.
          </p>
          <Form>
            <Input
              type={"email"}
              label={"Wprowadź adres email"}
              icon={atOutline}
              value={email}
              onChange={handleEmailChange}
              block
              required
            />

            <div style={{ marginTop: "32px" }}>
              <Button block onClick={handleSubmit}>
                {loading ? <Spinner size={24} /> : "Wyślij"}
              </Button>
            </div>
            <div style={{ marginTop: "16px" }}>
              <Alert isActive={!!errorMessage.length} error>
                {errorMessage}
              </Alert>
              <Alert isActive={!!successMessage.length} success>
                {successMessage}
              </Alert>
            </div>
          </Form>
        </WidgetSection>
      </Widget>
      <LinksWrapper>
        <LinkWrapper>
          <Link to={"/login"} icon={chevronBackOutline}>
            Powrót do logowania
          </Link>
        </LinkWrapper>
        <LinkWrapper>
          <Link to={"/"} icon={chevronBackOutline}>
            Powrót do strony głównej
          </Link>
        </LinkWrapper>
      </LinksWrapper>
    </LoginLayout>
  )
}

const Reset = () => {
  const client = useGuestApolloClient()

  return (
    <>
      <ApolloProvider client={client}>
        <ResetContent />
      </ApolloProvider>
    </>
  )
}

export default Reset
